export const environment = {
    production: true,
    elemental: true,
    deploymentName: 'Elemental',
    companyName: 'Elemental NA',
    website: 'elementalna.com',
    converterImageLogo: '/assets/images/elemental/logo.png',
    converterImageLogoSmall: '/assets/images/elemental/logo.png',
    converterWatermarkText: 'ELEMENTAL',
    dashboardLogoLight: '/assets/images/elemental/logo.png',
    dashboardLogoDark: '/assets/images/elemental/logo.png',
    dashboardLogoAspectRatio: 1.15,
    welcomeLogo: '/assets/images/elemental/logo.png',
    welcomePageUrl: 'https://elemental.biz',
    hasWelcomePageLinks: true,
    defaultApiEndpoint: 2,
    logLevel: 2,
    versionCheckUrl:
        'https://app-admin-elemental.dev.elemental.biz/version.json',
    azureMapsKey:
        '1ylXy1Xw4ENtG2DBwAaH1xZiSfmyWF0NwX1yy529xeSTPQVbwJ9yJQQJ99AKACYeBjF03xPwAAAgAZMP2wJ3',
};
